import React, { Component } from "react";
import axios from "axios";
// import Alert from 'react-bootstrap/Alert';

// retorna o código do cliente definido como consumidor
// é uma espécie de "define", uma aconstante
export function CodConsum() {
    return (1927);
}

export function SelectFormasPag(prefixo_id) {
    return (
        <>
        <option id={prefixo_id.id + "_0"} value="0">Selecione...</option>
        <option id={prefixo_id.id + "_1"} value="1">Boleto</option>
        <option id={prefixo_id.id + "_2"} value="2">C Crédito</option>
        <option id={prefixo_id.id + "_3"} value="3">C Débito</option>
        <option id={prefixo_id.id + "_4"} value="4">Cheque</option>
        <option id={prefixo_id.id + "_5"} value="5">Depósito</option>
        <option id={prefixo_id.id + "_6"} value="6">Dinheiro</option>
        <option id={prefixo_id.id + "_7"} value="7">PIX</option>
        <option id={prefixo_id.id + "_8"} value="8">Transferência</option>
        <option id={prefixo_id.id + "_9"} value="9">Vale</option>
        </>
    );
}

/* histórico de versões - comecei em 07/11/2024
 Versão 2024-07-04 22:32
    * incluída opção para importar dados de clientes do RedID
    * Lista até 350 vendas na lista de vendas
 Versão 2024-07-09 15:30
    * Alterado relatório de vendas para que na opção de quebra por dia/produto, considere o preço na chave, neste caso para 
      forçar a exibição de vendas com preço zerado
    * corrigido erro na função VenderCli.js->handleLocalizar, que dava pau ao filtrar cliente quando nome ou fantasia só tinham números
    * incluído campo origem_cliente na tb_cliente, para indicar se o cliente foi criado diretamente no CTC (C) ou se veio migrado do RedID (R)
    * tratamento deste campo na importação de clientes
    * exibição deste campo no detalhe do cliente e rearranjo dos campos de detalhe do cliente
 Versão 2024-11-14 22:42
    * criada funcionalidade para listar últimas 10 vendas
    * campo valor do desconto não habilitado para edicao
    * corrigido problema da alteração de vendas que nao estava considerando o % de desconto 
 Versão 2024-11-21 21:26
    * corrigidas filtros por nome fantasia q estavam sem o toString
    * gravação da data da atualização do redid (campo novo tb_clientes.last_update)
    * verificação do relatório financeiro, porém sem mudanças
 Versão 2024-11-28 23:00
    * mostra o número da venda na lista de parcelas para quitar
    * pede nome na tela de vendas quando cliente é CONSUMIDOR
    * mostra nome e cpf do cliente na consulta do detalhe da venda
    * idem no cupom de venda
Versão 2024-12-06
    * incluido campo de qtd bonificação; isso gerou alterações em vários programas
Versão 2024-12-08 08:30
    * inicializa campo qtd bonificacao com zero
Versão 2024-12-09 22:25
    * incluído na lista de vendas o nome do consumidor
    * filtros do relatório de vendas (exceto produto e cliente) ficam salvos
    * ajustado erro no VendasAlterar, precisei multiplicar as qtds por 1 no handlesubmit para poder forçar a passagem dos valores
    * pois quando a qtd bonificação por exemplo era zero ele dava erro de array nao definido
Versão 2024-12-18 20:55
    * mostra nome do consumidor na quitação e nos relatórios financeiros
Versão 2024-12-19 22:25
    * ajustes na impressao do cupom
Versão 2025-01-16 20:40
    * ajustado o VendasAlterar.php, multipliquei a qtd vendida, valor unit e qtd bonif por 1, pois na versao de produção 
      caso deixe algum campo com zero, não passa no post, e com isso a query fica sem valor no campo zerado; esta 
      alteração fiz direto em produção e repliquei no programa local, porém nao gerei o build novamente
    * incluido filtro de vendedor no VendasListar
    * incluido campo Vendedor na tabela de usuários
    * criado campo Vendedor na listagem de usuários
    * Data inicial e final da vendas listar salva para proximo uso
Versão 2025-02-04 22:02
    * ajustes apenas no programa de importação de clientes, para retirada de acentos
*/    
export class AppVer extends Component {
    render() {
        return (
            <>
            <div className='sVersao'>
                CTC - Versão 2025-01-16 20:40 - {process.env.NODE_ENV.substring(0,3)}
            </div>
            </>
        )
    }
}

export class UserLogged extends Component {
    render() {
        return (
            <>
            <div className='sUserLog'>Usuário: {localStorage.getItem("usuario")}</div><br/>
            </>
        )
    }
}

// devolve a data atual
export function CurDate(sFormat)
{
    var sCurDate = new Date();
    var dd = String(sCurDate.getDate()).padStart(2, '0');
    var mm = String(sCurDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = sCurDate.getFullYear();
    if (sFormat === "YMD")
        sCurDate = yyyy + '-' + mm + '-' + dd;
    else
        sCurDate = dd + "/" + mm + "/" + yyyy;
    return sCurDate;
}

// devolve a hora atual
export function CurTime()
{
    var sCurTime = new Date();
    var hh = String(sCurTime.getHours()).padStart(2, '0');
    var mm = String(sCurTime.getMinutes()).padStart(2, '0');
    var ss = String(sCurTime.getSeconds()).padStart(2, '0');
    sCurTime = hh + ":" + mm + ":" + ss;
    return sCurTime;
}

/* por enquanto esta funcao ainda nao esta sendo usada, 
mas o retorno aResponse está sempre voltando vazio para o chamador;
exemplo no Situacao.js
*/
export function GetDataBDViaAxios(phpProg, phpFunc)
{
    let aResponse = [];
    axios({
        method: 'get',
        url: `${process.env.REACT_APP_API}TrataGetPost.php`,
        params: {
        prog_trata: phpProg,
        func_trata: phpFunc
        }
    })
    .then((response) => {
        // console.log(response.data);
        aResponse = response.data;
        if (! Array.isArray(aResponse))
            console.log(aResponse); 
        alert(aResponse);
    })
    .catch(function (error) { console.log(error); alert(error) })
    return (aResponse);
}

// retorna o array com dados dos tipos de transações de estoque
// foi criado pois optou-se por não ter uma tabela no banco de dados com estas
// informações já que serão estáticas
export function GetArrTransEst()
{
    return [
        {cod: "SVI", tipo: "auto",   fator: -1, desc: "Venda ou bonificação c/ entrega imediata"}, 
        {cod: "SVP", tipo: "auto",   fator: -1, desc: "Venda ou bonificação c/ entrega pendente"},
        {cod: "SEN", tipo: "auto",   fator: -1, desc: "Entrega de venda ou bonificação realizada"},
        {cod: "SPE", tipo: "manual", fator: -1, desc: "Perda"},
        {cod: "ECO", tipo: "manual", fator:  1, desc: "Compra"},
        {cod: "ETC", tipo: "manual", fator:  1, desc: "CT > CEA"},
        {cod: "STC", tipo: "manual", fator: -1, desc: "CEA > CT"},
        // o fator de TRA é -1 pq será usado para gerar a 1a movimentação, STR
        {cod: "TRA", tipo: "manual", fator: -1, desc: "Transfor"}, 
        {cod: "ETR", tipo: "auto",   fator:  1, desc: "Transformado de"},
        {cod: "STR", tipo: "auto",   fator: -1, desc: "Transformado p/"},
        {cod: "EEM", tipo: "manual", fator:  1, desc: "Emprést"},
        {cod: "SEM", tipo: "manual", fator: -1, desc: "Devoluç"},
        {cod: "EAJ", tipo: "manual", fator:  1, desc: "Ajuste +"},
        {cod: "SAJ", tipo: "manual", fator: -1, desc: "Ajuste -"},
        // quando uma venda é alterada, como não tem o id da linha na mov de estoque, nao da para saber se qdo
        // vendeu originalmente era SVI ou SVP, por isso criei esta, apenas para registrar a alteração
        {cod: "SAV", tipo: "auto", fator: -1, desc: "Saida Alteração Venda"},
        {cod: "SAB", tipo: "auto", fator: -1, desc: "Saida Alteração Bonificação"},
    ]
    /* descrição um pouco mais detalhada:
    SVI => saída do estoque por venda ou bonificação com entrega imediata do produto ao cliente (ou em até 15 minutos da venda)
    SVP => venda ou bonificação onde a entrega será a posterior (é o default quando salva a venda)
    SEN => venda ou bonificação onde a entrega foi realizada a partir de 15 minutos da venda
    SPE => baixa no estoque por perda do produto (estragou, perdeu, roubou)
    ECO => entradano estoque por compra do produto
    ETC => transferencia do CT para o CEASA
    STC => transferencia do CEASA para o CT
    TRA => na verdade é fake; indica que devem ser gerados 2 lançamentos, um ETR e outro STR
    ETR => quando o produto A é convertido em B, ETR indica a entrada em B
    STR => quando o produto A é convertido em B, STR indica a saída em A
    EEM => quando a CT empresta produto de um parceiro, indica a entrada do produto
    SEM => quando a CT devolve o produto para o parceiro, indica a saída do produto 
    EAJ => ajuste de inventário, indica entrada no estoque
    SAJ => ajuste de inventário    
    SBI => saída do estoque por bonificacao com entrega imediata do produto ao cliente (ou em até 15 minutos da venda)
    SBP => bonificacao onde a entrega será a posterior (é o default quando salva a venda)
    */
}

export function Separador() {
    return <div className='separador' />;
}

// formata uma data para exibição
// YYYY-MM-DD para DD/MM/YY
export function FormataData(sDataYMD)
{
    return (sDataYMD.substr(8,2) + "/" + sDataYMD.substr(5,2) + "/" + sDataYMD.substr(0,4));
}

/* ao que parece esta função não é necessária, visto que apenas chama a toFixed... é que descobri a toFixed apenas depois
   de já ter feito a FormataValor e usado em vários programas; vou deixar por enquanto a FormataValor, mas depois pode ser
   avaliada a substituição dela diretamente pela toFixed
*/   
// esta funcao exibe um valor formatado com virgula para separar decimais e ponto para separar milhar
// porém, quando ela é chamada para formatar um valor a ser usado no atributo "value" da tag input, esta precisa
// receber sempre o valor formatado com ponto para separar decimais; por isso é feito o teste usando o parametro sElement
export function FormataValor(nValor, sElement)
{
    nValor = nValor * 1;

    /*
    console.log("nValor = ");
    console.log(nValor);
    console.log(isNaN(nValor));
    */
   
    var nRet = nValor.toFixed(2);

    if (sElement !== "input")
        nRet = nValor.toLocaleString("pt-BR", {minimumFractionDigits:2, maximumFractionDigits:2});
//    console.log(nRet);
//    if (sElement === "input")
//        nRet = nRet.replace(".", ";").replace(",", ".").replace(";", ",");
    return (nRet);
}

// seta o campo valor unitário baseado no produto e na unidade de medida selecionados
// chamado qdo clica na unidade de medida
export function SetaVUni(iUnid, aProds, iProd, cSufixo) {
    let sBt = "", sBo = "";
    var nValUnit = 0;
    var nValTotal = 0;
    if (cSufixo === "") // venda
    {
        sBt = "#Qtd";
        sBo = "#QBo";
    }
    else if (cSufixo === "O") // movimentar, produto origem
    {
        sBt = "#QtdOrig";
        sBo = "#QBoOrig";
    }
    else // (cSufixo === "D") // movimentar, produto destino
    {
        sBt = "#QtdDest";
        sBo = "#QBoDest";
    }
    if ((iProd >= 0) && (iUnid >= 0))
    {
        if (cSufixo === "") // venda
            document.querySelector("#VUni").disabled = false;
        document.querySelector(sBt).disabled = false;
        document.querySelector(sBt).focus();
        document.querySelector(sBo).disabled = false;
        nValUnit = aProds[iProd].aPrecs[iUnid];
        nValTotal = nValUnit;
    }
    // se for venda, o valor e a qtd sempre sao setados, ou para o valor do produto e unidade selecionados, ou para zero, caso
    // tenha acabado de selecionar o produto
    if (cSufixo === "")
    {
        document.querySelector("#VUni").value = FormataValor(parseFloat(nValUnit), "input");
        document.querySelector("#VTot").value = FormataValor(parseFloat(nValTotal), "input");
        document.querySelector("#QBo").value = 0;
    }
//    document.querySelector(sBt).value = 1;    // comentado em 03/01/2023 a pedido de Matheus
}

  // obtem e mostra o estoque
export function handleEstoque (aProds, aUnids) {
    let cProd = document.querySelector("input[type='radio'][name='bt_prod']:checked").value;
    let cUnid = document.querySelector("input[type='radio'][name='bt_unid']:checked").value;
  axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
      codp: aProds[cProd].cod,
      codu: aUnids[cUnid].cod,
      prog_trata: "./Estoque/Situacao.php",
      func_trata: "Situacao"
      }
    })
    .then((response) => {
      // console.log(response.data);
      document.querySelector("#qEstDis").value = response.data[0]["qtd_disponivel"];
      document.querySelector("#qEstRet").value = response.data[0]["qtd_a_retirar"];
      document.querySelector("#qEstTot").value = response.data[0]["qtd_est_total"];
    })
    .catch(function (error) { console.log(error); alert(error) });
  }


// mostra as unidades de medida associadas ao produto que foi clicado pelo usuário
// que sao aquelas para as quais existe preco sugerido cadastrado
// chamada ao renderizar a tela ou ao clicar num produto (o qual altera o state de iSelProd)
// props.cSufixo será O para produto origem, D para produto destino (ambos chamados na tela de movimentação), 
// ou V qdo for chamado da tela de vendas
export function ShowUnids (props) {
    // uncheck a unidade eventualmente selecionada anteriormente
//    console.log(props);
    var oBtUnid = document.querySelector("input[type='radio'][name='bt_unid" + props.cSufixo + "']:checked");
    if (oBtUnid !== null)
        oBtUnid.checked = false;
    if (props.iSelProd >= 0)
    {
        return (
        <ul className="btn-unid">
        {props.aUnids.map((oUnid, index) => (  // percorre o array de unidades
            (props.aProds[props.iSelProd].aPrecs[index] !== 0 && ( // se existe preço para o produto na unidade, mostra o botão
            <li key={index}>
                {/* o input é basiamente o mesmo, só repeti por conta do checked q nao consegui colocar dentro do input */ }
                {index === props.iSelUnid && 
                    <input required type="radio" id={"u"+index} value={index} name={"bt_unid" + props.cSufixo} defaultChecked
                        onClick={(e) => {SetaVUni(index, props.aProds, props.iSelProd, props.cSufixo); 
                            handleEstoque(props.aProds, props.aUnids)}}
                    />
                }
                {index !== props.iSelUnid && 
                    <input required type="radio" id={"u"+index} value={index} name={"bt_unid" + props.cSufixo}
                        onClick={(e) => {SetaVUni(index, props.aProds, props.iSelProd, props.cSufixo); 
                            handleEstoque(props.aProds, props.aUnids)}}
                    />
                }
                <label htmlFor={"u"+index}>{oUnid.desc}</label>
            </li>))
        ))}
        </ul>
        )
    }
    /*
    else
        return <Alert variant="warning">Escolha um produto...</Alert>
    */
}

export function LeProdUnidPrec()
{
    // produtos, unidades e precos lidos da view de saldos de estoque
    let aPUP = [];
    // aProds guarda id, descrição e precos de um produto; aProdsIdx serve para controlar 
    // se um produto já existe no array ou não, e qual seu índice; idem para Unids e UnidxIdx
    let aProds = [], aProdsIdx = [];
    let aUnids = [], aUnidsIdx = [];

    let pIdx = 0; // índice corrente do produto em aProds e aProdsIdx
    let uIdx = 0; // índice corrente da unidade em aUnids e aUnidsIdx

    let pIdxProx = 0; // próxima posção livre em aProds e aProdsIdx
    let uIdxProx = 0; // próxima posção livre em aUnids e aUnidsIdx

    // usada a view de saldos de estoque pois ela contem ie e descrição tanto da unidades
    // quanto dos produtos, e tb o valor sugerido; assume-se que ela sempre trará pelo menos
    // uma linha por produto, tendo ou não saldo em estoque
    axios({
        method: 'get',
        url: `${process.env.REACT_APP_API}TrataGetPost.php`,
        params: {
        prog_trata: "./Estoque/Situacao.php",
        func_trata: "Situacao"
        }
    })
    .then((response) => {
        aPUP = response.data ;
        // transfere os dados lidos da tabela os arrays
        for (let i=0; i < aPUP.length; i++)
        {
            // verifica se o produto já existe em aProdsIdx
            pIdx = aProdsIdx.indexOf(aPUP[i].id_produto);
            if (pIdx === -1)
            {
                // se não existe, inclui em aProdsIdx, salva a posição atual em pIdx, 
                // incrementa a próxima posição e inclui o produto em aProds
                aProdsIdx[pIdxProx] = aPUP[i].id_produto;
                pIdx = pIdxProx;
                pIdxProx++;
                aProds[pIdx] = {idx: pIdx, cod: aPUP[i].id_produto, desc: aPUP[i].desc_produto, orig: aPUP[i].orig_produtos, aPrecs: []};
            }
            // verifica se a unidade já existe em aUnidsIdx
            uIdx = aUnidsIdx.indexOf(aPUP[i].id_unidade);
            if (uIdx === -1)
            {
                // se não existe, inclui em aUnidsIdx, salva a posição atual em uIdx, 
                // incrementa a próxima posição e inclui a unidade em aUnids
                aUnidsIdx[uIdxProx] = aPUP[i].id_unidade;
                uIdx = uIdxProx;
                uIdxProx++;
                aUnids[uIdx] = {cod: aPUP[i].id_unidade, desc: aPUP[i].desc_unidade};
            }
        }
        // cria precos zerados para todas os produtos e unidades
        for (let p=0; p < aProds.length; p++) {
            for (let u=0; u < aUnids.length; u++)
                aProds[p].aPrecs[u] = 0;
        }
        // percorre novamente o array para setar os precos
        for (let i=0; i < aPUP.length; i++) {
            pIdx = aProdsIdx.indexOf(aPUP[i].id_produto);
            uIdx = aUnidsIdx.indexOf(aPUP[i].id_unidade);
            // seta o preço do produto e unidade de medida atual
            aProds[pIdx].aPrecs[uIdx] = aPUP[i].valor_unit_sugerido;
        }
    })
    .catch(function (error) { console.log(error); alert(error) });

//    console.log(aProds);
    return {aProds, aUnids};
}

/* csr20240723
// a unica mudança entre esta função e a trataAxios
// é que a Old usa o await, e outra não
// isso foi feito para resolver o problema do não 
// funcionamento do Axios no iOS 
export async function trataAxiosOld(dadosToAxios)
{
//    alert(`${process.env.REACT_APP_API}TrataGetPost.php`);
    // versao q estava funcionando
    const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}TrataGetPost.php`,
        data: dadosToAxios,
    });
    //
    // var contador = 1;
    // while ((typeof response.data === 'undefined') && (contador < 10000))
    //     contador = contador + 1;
    // 
// comentei o if abaixo em 07/12/2023, pois o mesmo estava impedindo que mensagens de erro ou de sucesso fossem mostradas para os usuários;
// na verdade não sei pq fiz este if testando o tipo do objeto; me parece q uma vez estava dando erro, mas nao me recordo onde   
//    if (typeof response.data !== 'object')
//    {
        if (typeof response.data.msg === 'undefined')
        {
            alert("Problemas ao processar a requisição. Informações para suporte: " + 
                "response.data.msg = " + response.data.msg + "; " + 
                "response.data = " + response.data + "; " + 
                "response = " + response);
        }
        else if (response.data.msg === "OK")
            alert("Dados salvos com sucesso !");
        else if (response.data.msg === "EX OK")
            alert("Dados excluídos com sucesso !");
        else if (response.data.msg === "ALT OK")
            alert("Dados alterados com sucesso !");
        else if (response.data.msg === "JEP")
            alert ("Já existe preço para este produto e unidade !");
        else if (response.data.msg === "JEC")
            alert ("Já existe cliente com este CNPJ !");
        else if (response.data.msg === "")
            alert("Problemas ao salvar os dados.");
        else
        {
            alert("typeof response.data.msg = " + typeof response.data.msg);
            alert("response.data.msg = " + response.data.msg);
            alert("response.data = " + response.data);
            alert("response = " + response);
        }
//    }
//    console.log(response.data);
    return (response.data);
}
*/

export async function trataAxios(dadosToAxios)
{
    var ret;
    axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}TrataGetPost.php`,
        data: dadosToAxios,
    })
    .then((response) => {
        if (typeof response.data.msg === 'undefined')
        {
            alert("Problemas ao processar a requisição. Informações para suporte: " + 
                "response.data.msg = " + response.data.msg + "; " + 
                "response.data = " + response.data + "; " + 
                "response = " + response);
        }
        else if (response.data.msg === "OK")
            alert("Dados salvos com sucesso !");
        else if (response.data.msg === "EX OK")
            alert("Dados excluídos com sucesso !");
        else if (response.data.msg === "ALT OK")
            alert("Dados alterados com sucesso !");
        else if (response.data.msg === "JEP")
            alert ("Já existe preço para este produto e unidade !");
        else if (response.data.msg === "JEC")
            alert ("Já existe cliente com este CNPJ !");
        else if (response.data.msg === "")
            alert("Problemas ao salvar os dados.");
        else
        {
            alert("typeof response.data.msg = " + typeof response.data.msg);
            alert("response.data.msg = " + response.data.msg);
            alert("response.data = " + response.data);
            alert("response = " + response);
        }
        ret = response;
    })
    .catch(function (error) {alert(error + " (check your internet connection / database connection)")});
    return (ret);
}

export function descTipoCli(cTipo)
{
    return (cTipo === "C" ? "Consumidor" : (cTipo === "J" ? "Pessoa Jurídica" : "Pessoa Física"));
}

export function SelectTipoCli() {
    return (
        <>
        <option value="F">Pessoa Física</option>
        <option value="J">Pessoa Jurídica</option>
        <option value="C">Consumidor</option>
        </>
    );
}